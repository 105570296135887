import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78')
];

export const server_loads = [0,5,7,6,12,8,9,13,10,2,3];

export const dictionary = {
		"/(auth)": [~14],
		"/(protected)/dashboard": [~27,[5]],
		"/(protected)/dashboard/(admin)/actions": [28,[5]],
		"/(protected)/dashboard/(admin)/analytics/customers": [~29,[5]],
		"/(protected)/dashboard/(admin)/analytics/feedbacks": [~30,[5]],
		"/(protected)/dashboard/(admin)/analytics/sales": [~31,[5]],
		"/(protected)/dashboard/(operation)/calendar": [60,[5]],
		"/(protected)/dashboard/(crud)/categories": [~37,[5,7]],
		"/(protected)/dashboard/(crud)/destinations": [~38,[5,7]],
		"/(protected)/dashboard/(crud)/destinations/[id]": [~39,[5,7]],
		"/(protected)/dashboard/(admin)/finance": [~32,[5]],
		"/(protected)/dashboard/(admin)/finance/(finance)/billing": [33,[5,6]],
		"/(protected)/dashboard/(admin)/finance/bookings": [~35,[5]],
		"/(protected)/dashboard/(admin)/finance/(finance)/disputes": [34,[5,6]],
		"/(protected)/dashboard/(crud)/highlights": [~40,[5,7]],
		"/(protected)/dashboard/(crud)/highlights/[id]": [~41,[5,7]],
		"/(protected)/dashboard/(crud)/media": [~42,[5,7]],
		"/(protected)/dashboard/(crud)/restaurants": [~43,[5,7]],
		"/(protected)/dashboard/(sales)/sales": [~77,[5,12]],
		"/(protected)/dashboard/(operation)/self-drive": [61,[5]],
		"/(protected)/dashboard/(crud)/services": [~44,[5,7]],
		"/(protected)/dashboard/(crud)/suppliers": [~45,[5,7]],
		"/(protected)/dashboard/(crud)/suppliers/map": [~49,[5,7]],
		"/(protected)/dashboard/(crud)/suppliers/new": [50,[5,7]],
		"/(protected)/dashboard/(crud)/suppliers/[id]": [~46,[5,7,8]],
		"/(protected)/dashboard/(crud)/suppliers/[id]/pricing": [48,[5,7,8]],
		"/(protected)/dashboard/(crud)/suppliers/[id]/[serviceId]": [~47,[5,7,8]],
		"/(protected)/dashboard/(crud)/templates/days": [~51,[5,7]],
		"/(protected)/dashboard/(crud)/templates/days/[id]": [~52,[5,7]],
		"/(protected)/dashboard/(crud)/templates/emails": [~53,[5,7]],
		"/(protected)/dashboard/(crud)/templates/packages": [~54,[5,7]],
		"/(protected)/dashboard/(crud)/users": [~55,[5,7]],
		"/(protected)/dashboard/(crud)/users/[id]": [~56,[5,7]],
		"/(protected)/dashboard/(crud)/vehicles": [~57,[5,7]],
		"/(protected)/dashboard/(crud)/vehicles/manager": [59,[5,7]],
		"/(protected)/dashboard/(crud)/vehicles/[id]": [~58,[5,7]],
		"/(protected)/dashboard/(admin)/websites/deluxe-travel": [36,[5]],
		"/(protected)/dashboard/(sales)/[customer=customer]": [~73,[5,12]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]": [~62,[5,9]],
		"/(protected)/dashboard/(sales)/[customer=customer]/[id]": [74,[5,12,13]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/(itinerary)": [63,[5,9,10,11]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/calendar": [65,[5,9,10]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/checklist": [~66,[5,9,10]],
		"/(protected)/dashboard/(sales)/[customer=customer]/[id]/delete": [~75,[5,12,13]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/invoice": [~67,[5,9,10]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/messages": [68,[5,9,10]],
		"/(protected)/dashboard/(sales)/[customer=customer]/[id]/new": [~76,[5,12,13]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/package": [69,[5,9,10]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/payments": [70,[5,9,10]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/settings": [71,[5,9,10]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/suppliers": [~72,[5,9,10]],
		"/(protected)/dashboard/(proposals)/[proposal=proposal]/[id]/(itinerary)/[day]": [~64,[5,9,10,11]],
		"/(client)/questionnaire/[id]": [~22,[2]],
		"/(client)/questionnaire/[id]/success": [23,[2]],
		"/(client)/requests/[supplierId]/[requestId]/[[group]]": [~24,[2]],
		"/(auth)/reset-password/[token]": [~15],
		"/(views)/sales": [~78],
		"/(client)/trip/[id]": [~25,[2]],
		"/(client)/view/[id]": [~26,[2]],
		"/(client)/[proposal=proposal]/[id]": [~16,[2,3]],
		"/(client)/[proposal=proposal]/[id]/(book)/order": [~17,[2,3,4]],
		"/(client)/[proposal=proposal]/[id]/(book)/overview": [18,[2,3,4]],
		"/(client)/[proposal=proposal]/[id]/(book)/payments": [19,[2,3,4]],
		"/(client)/[proposal=proposal]/[id]/(book)/review": [~20,[2,3]],
		"/(client)/[proposal=proposal]/[id]/(book)/review/sent": [21,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';